import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Page404 from "./Page404/Page404";
import Map from "./Map/Map";

export default function Main () {
    return (
        <main>
            <Switch>
                <Route exact path='/' component={Map}/>
                <Route path="*" component={Page404} status={404}/>
            </Switch>
        </main>
    );
}
