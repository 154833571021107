import s from './Body.module.css';
import React, {useState, useEffect} from 'react';
import {Loading} from 'element-react';
import Header from "./Header/Header";
import Main from "./Main/Main";
import Auth from "./Auth/Auth";
import {getUserId, setApiTokenToCookie, setCookie, sendRequest, empty} from "../helpers/helpers";

function Body() {
    const [isAuth, setIsAuth] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const userId = getUserId();

    window.authError = authError;

    function authError() {
        setIsAuth(false);
        setApiTokenToCookie('');
        setCookie('userId', '')
    }

    useEffect(() => {
        async function authCheck() {
            const response = await sendRequest('AuthCheck', {"id": ""});
            if (response && response.data.result)
                return response.data.result.status === true;

            return false;
        }

        authCheck().then(r => {
            setIsAuth(r)
        });

        setIsLoading(false);
    }, []);

    function authState(status) {
        setIsAuth(status);
    }

    if (isLoading === true) {
        return <Loading fullscreen={true} className={s.loading_auth}/>;
    } else if (isAuth === false || empty(userId)) {
        return <Auth func={authState}/>
    }

    return (
        <div className={s.app}>
            <Header/>
            <Main/>
        </div>
    );
}

export default Body;
