import React, {useState} from 'react';
import s from './Auth.module.css';
import {Form, Button, Input, Message} from 'element-react';
import {authUser, setApiTokenToCookie, setCookie} from "../../helpers/helpers";
import {projectName} from "../../helpers/constants";


export default function Auth(props) {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    setTimeout(() => {
        setStatus(s.active);
    }, 500);

    function showError(message) {
        Message({
            showClose: true,
            message: message,
            type: 'error',
        });
    }

    async function onSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        const form = {
            user: login,
            pass: password
        };
        let userState = await authUser(form);

        setIsLoading(true);

        if (userState.data.result.response === true ) {
            setApiTokenToCookie(userState.headers['x-xsrf-token']);
            setCookie('userId', userState.data.result.id, {path: '/'});
            props.func(true);
        } else {
            if (typeof userState.data.result.error_msg != "undefined")
                showError(userState.data.result.error_msg);
            else if (typeof userState.data.error.message != "undefined")
                showError(userState.data.error.message);
            else
                showError('Ошибка авторизации пользователя.');
        }

        setIsLoading(false);
    }

    function inputKeyPress(e) {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    }

    let className = s.b_auth + " board " + status;
    const form = {
        login: login,
        password: password
    };

    return (
        <main className={className}>
            <Form model={form} onSubmit={onSubmit}>
                <h2>{projectName}</h2>
                <Input className={s.elInput} placeholder="Ваш логин" value={login} onChange={(e) => setLogin(e)}
                       onKeyPress={inputKeyPress}/>
                <Input className={s.elInput} placeholder="Пароль" type={s.password} value={password} onChange={(e) => setPassword(e)}
                       onKeyPress={inputKeyPress}/>
                <Button type="primary" className={s.login} onClick={onSubmit}
                        loading={isLoading}>Войти</Button>
            </Form>
        </main>
    );
}


