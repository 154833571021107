import s from './Header.module.css';
import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import { projectName } from '../../helpers/constants';
import {getUserId, sendRequest} from "../../helpers/helpers";

export default function Header() {
    const [inspectionRate, setInspectionRate] = useState(0);

    useEffect(() => {
        async function getInspectionRate() {
            const response = await sendRequest('ge_getInspectionRate', {id: getUserId()});

            if (response && response.data.result && response.data.result.result)
                return response.data.result.result;

            return 0;
        }

        getInspectionRate().then(r => {setInspectionRate(r); window.inspectionRate = r;});
    }, []);

    return (
        <header className={s.b_header}>
            <NavLink to="/" className={s.b_header__logo}>
                <div className={s.b_header__title}>{projectName}</div>
            </NavLink>
            <div className={s.right}>
                <div className={s.rate}>Норма обходов: {inspectionRate}</div>
                <a href="#" className={s.b_logout} onClick={window.authError}>Выйти</a>
            </div>
            <div className="clear"/>
        </header>
    );
}
