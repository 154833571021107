import React, {useEffect, useState} from 'react';
import {DatePicker, Select} from 'element-react';
import Statistic from "./Statistic/Statistic";

// import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import Map from "../../../ui/Map";
import {
    empty,
    formatDate,
    formatDateForResponse,
    getUserId,
    removeDuplicates,
    sendRequest
} from "../../../helpers/helpers";

export default function Page() {
    const [geo, setGeo] = useState({});
    const [periodIndex, setPeriodIndex] = useState(1);
    const [periodList, setPeriodList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [currentEmployee, setCurrentEmployee] = useState('');
    const [date, setDate] = useState(new Date());


    useEffect(() => {
        async function getEmployeeList() {
            const response = await sendRequest('ge_getEmployeeList', {"id": getUserId()});

            let list = [];
            if (response && response.data.result && response.data.result.employees)
                list = response.data.result.employees;

            return list;
        }

        getEmployeeList().then(r => {
            setEmployeeList(r);
        });
    }, []);

    useEffect(() => {
        async function getGeoListByEmployee() {
            const data = {
                login: currentEmployee,
                dateStart: formatDateForResponse(date),
                dateFinish: formatDateForResponse(new Date(date.getTime() + 3600 * 24 * 1000)) // in ms
            };
            const response = await sendRequest('ki_getGeoCoordinates', data);

            let list = [];
            if ( response && response.data.result && response.data.result.result) {
                list = response.data.result.result.map(i => {
                    const date = (new Date(i.date));
                    const hours = ('0' + date.getHours()).slice(-2);
                    let minutes = date.getMinutes();
                    return {
                        lat: i.latitude,
                        lon: i.longitude,
                        time: date.getTime() / 1000,
                        title: 'Время: ' + formatDate(date),
                        rounded_title: (minutes < 30) ? hours + ':00' : hours + ':30',
                        rounded_id: (minutes < 30) ? hours + '_00' : hours + '_30'
                    }
                });
            }
            list = !empty(list) ? removeDuplicates(list) : [];

            let result = {};
            list.forEach(i => {
                if (result[i.rounded_title] === undefined)
                    result[i.rounded_title] = [];

                result[i.rounded_title].push(i);
            });

            return result;
        }

        if (date && currentEmployee) {
            getGeoListByEmployee().then(r => {
                setGeo(r);
                setPeriodList(Object.keys(r));
                setPeriodIndex(Object.keys(r)[0]);
            });
        }
    }, [date, currentEmployee]);

    let classNameDate = 'b_map_filter__box';
    let classNameTimeLine = 'b_timeline';

    if (currentEmployee) {
        classNameDate += ' active';

        if (periodList.length > 0)
            classNameTimeLine += " active";
    }

    return (
        <div className="b_pages__index">
            <Map geo={geo[periodIndex] ? geo[periodIndex] : []}/>

            <div className="b_map_filter">
                <div className="b_map_filter__box active">
                    <div className="b_map_filter__title">Сотрудник</div>
                    <Select className="width-full" value={currentEmployee}
                            onChange={(e) => setCurrentEmployee(e)}
                            placeholder={'Выбрать сотрудника'}>
                        {
                            employeeList.map(employee => {
                                return <Select.Option key={employee} label={employee} value={employee}/>
                            })
                        }
                    </Select>
                </div>
                <div className={classNameDate}>
                    <div className="b_map_filter__title">Дата</div>
                    <DatePicker placeholder="год-м-д" value={date}
                                onChange={setDate}/>
                </div>
                <Statistic />
            </div>
            <div className={classNameTimeLine}>
                {periodList.map((el) => {
                    let className = "b_timeline__box";
                    if (el === periodIndex) {
                        className += " active";
                    }
                    return <div className={className} key={el.replace(':', '_')}
                                onClick={() => {
                                    setPeriodIndex(el)
                                }}>{el}</div>
                })}
            </div>
            <div className="clear"/>
        </div>
    );
}
