import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Body from './components/Body';
import { BrowserRouter } from 'react-router-dom'

import './helpers/element-react';
import 'element-theme-default';

ReactDOM.render(
    <BrowserRouter>
        <Body />
    </BrowserRouter>,
  document.getElementById('root')
);
