import axios from "axios";

import { Message } from 'element-react';
import {getApiTokenFromCookie} from "./helpers";

axios.defaults.headers.common = {
	'X-Xsrf-Token': getApiTokenFromCookie(),
	'Content-Type':'application/x-www-form-urlencoded',
	'Accept': '*/*'
};
const  http = axios.create({
	baseURL: "https://api.regenergy.ru/api2/rmd_vdgo/",
	responseType: "json",
});

http.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401) {		
		window.authError();
	} else{
		Message({
				showClose: true,
				message: "AJAX неверный код ответа: #"+error.response.status,
				type: 'error',
			});
	}
	return error.response;
});

window.axios = http;

export default http;


    