import React, {useState, useEffect} from 'react';
import {Card, DatePicker, Input, Radio, Table, Pagination, Icon, Button} from 'element-react';

import s from './Statistic.module.css';
import {formatShortDate, sendRequest} from "../../../../helpers/helpers";
import {triggerBase64Download} from "../../../../helpers/base64";

export default function Statistic() {
    const [common, setCommon] = useState({
        all: 0,
        accepted: 0,
        canceled: 0,
        on_rate: 0
    });
    const [statistic, setStatistic] = useState([]);
    const [filteredStatistic, setFilteredStatistic] = useState([]);
    const [isOpened, setIsOpened] = useState(false);
    const [filter, setFilter] = useState({
        query: '',
        status: 'all'
    });
    const [date, setDate] = useState(new Date());
    const [pagination, setPagination] = useState({
        pageSize: 5,
        page: 0,
        pageCount: 0
    });

    useEffect(() => {
        async function getStatistic() {
            if (!date)
                return [];

            const data = {
                dateStart: formatShortDate(date, '-'),
                dateFinish: formatShortDate(new Date(date.getTime() + 3600 * 24 * 1000 + 1), '-')
            };
            const response = await sendRequest('ge_getInspectionStatistic', data);

            if (response && response.data.result && response.data.result.Inspections)
                return response.data.result.Inspections;

            return [];
        }

        getStatistic().then(r => setStatistic(r));
    }, [date]);

    useEffect(() => {
        setCommon({
            all: statistic.reduce((sum, current) => {
                return sum + current.all;
            }, 0),
            accepted: statistic.reduce((sum, current) => {
                return sum + current.accepted;
            }, 0),
            canceled: statistic.reduce((sum, current) => {
                return sum + current.canceled;
            }, 0),
            on_rate: statistic.reduce((sum, current) => {
                return sum + current.on_rate;
            }, 0),
        });
        // строка поиска
        let filtered = statistic.filter(item => item.name.toLowerCase().indexOf(filter.query.toLowerCase()) > -1);

        // все/есть непринятые/нет непринятых
        switch (filter.status) {
            case "have_canceled":
                filtered = filtered.filter(item => item.canceled > 0);
                break;
            case "have_not_canceled":
                filtered = filtered.filter(item => item.canceled === 0);
                break;
            default:
                break;
        }

        setFilteredStatistic(filtered)
    }, [statistic, date, filter]);

    useEffect(() => {
        setPagination({
            ...pagination,
            pageCount: Math.ceil(filteredStatistic.length / pagination.pageSize),
            page: 0
        });
    }, [filteredStatistic]);

    async function downloadReport() {
        if (!date)
            return [];

        const data = {
            dateStart: formatShortDate(date, '-'),
            dateFinish: formatShortDate(new Date(date.getTime() + 3600 * 24 * 1000 + 1), '-')
        };
        const response = await sendRequest('ge_getInspectionReport', data);

        if (response && response.data.result && response.data.result.report)
            return 'data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data.result.report;

        return [];
    }

    function renderSpan(data) {
        const inspectionRate = window.inspectionRate ?? 0;
        const styles = (data.accepted < inspectionRate) ? {color: 'red'} : {};
        return <span style={styles}>{data.accepted}</span>
    }

    return (
        <Card className={s.box}>
            <div className={s.header}>
                <div className={s.titleContainer}>
                    <div className={s.title}>Статистика обходов</div>
                    <div className={s.caption}>Всего за день</div>
                </div>
                <div className={s.toggleCard} onClick={() => setIsOpened(!isOpened)}>
                    {isOpened ? <Icon name={'arrow-up'}/> : <Icon name={'arrow-down'}/>}
                </div>
            </div>
            <div className={s.common + ' ' + (isOpened ? s.hidden : '')}>
                <div>Всего: {common.all}</div>
                <div>Принятых: {common.accepted}</div>
                <div>Непринятых: {common.canceled}</div>
                <div>На оценке: {common.on_rate}</div>
            </div>
            <div className={s.employees + ' ' + (isOpened ? '' : s.hidden)}>
                <div className={s.title}>
                    <div>Фильтр</div>

                    <div className={s.datePicker}>
                        <DatePicker placeholder="год-м-д" value={date}
                                    onChange={setDate}/>
                    </div>
                </div>
                <div className={s.filter}>
                    <div className={s.search}>
                        <Input type='text'
                               value={filter.query}
                               onChange={(value) => setFilter({...filter, query: value})}
                               placeholder={'в найденном'}/>
                    </div>
                    <div className={s.foundCount}>Найдено: {filteredStatistic.length}</div>
                    <div className={s.statusControl}>
                        <Radio.Group value={filter.status} onChange={(value) => setFilter({...filter, status: value})}>
                            <Radio.Button value="all">Все</Radio.Button>
                            <Radio.Button value="have_canceled">Есть непринятые</Radio.Button>
                            <Radio.Button value="have_not_canceled">Нет непринятых</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <div className={s.table}>
                    <Table
                        columns={[
                            {label: 'ФИО сотрудника', prop: 'name'},
                            {label: 'Всего', prop: 'all'},
                            {
                                label: 'Принятых',
                                prop: 'accepted',
                                render: renderSpan
                            },
                            {label: 'Непринятых', prop: 'canceled',},
                            {label: 'На оценке', prop: 'on_rate'},
                        ]}
                        data={filteredStatistic.slice(pagination.pageSize * pagination.page, pagination.pageSize * pagination.page + pagination.pageSize)}
                        emptyText={"Не найдено"}
                    />
                </div>
                <div className={s.bottomControlPanel}>
                    <div className={s.pagination}>
                        <Pagination layout="prev, pager, next"
                                    total={filteredStatistic.length}
                                    onCurrentChange={(page) => setPagination({...pagination, page: page - 1})}
                                    pageSize={pagination.pageSize}
                                    pageCount={pagination.pageCount}
                                    currentPage={pagination.page + 1}
                        />
                    </div>
                    { statistic !== undefined && statistic.length > 0 ?
                        <div className={s.downloadReport}>
                            <Button type='primary' onClick={() => {
                                downloadReport().then(r => triggerBase64Download(r, 'InspectionReport-' + formatShortDate(date, '')))
                            }}>Выгрузить отчёт</Button>
                        </div>
                        : ''
                    }
                </div>
            </div>
        </Card>
    );
}
