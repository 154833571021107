import React, { Component } from 'react';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

L.Polyline.plotter = L.Polyline.extend({
	_lineMarkers: [],
	_editIcon: L.divIcon({className: 'leaflet-div-icon leaflet-editing-icon'}),
	_halfwayPointMarkers: [],
	_existingLatLngs: [],
	options: {
		weight: 2,
		color: '#1f9813',
		readOnly: false,
	},
	initialize: function (latlngs, options) {
		this._setExistingLatLngs(latlngs);
		L.Polyline.prototype.initialize.call(this, [], options);
	},
	onAdd: function (map) {
		L.Polyline.prototype.onAdd.call(this, map);
		this._map = map;
		this._plotExisting();
		if (!this.options.readOnly) {
			this._bindMapClick();
		}
	},
	onRemove: function () {

		for (const pointMarkerIndex in this._halfwayPointMarkers) {
			this._map.removeLayer(this._halfwayPointMarkers[pointMarkerIndex]);
		}
		for (const lineMarkerIndex in this._lineMarkers) {
			this._map.removeLayer(this._lineMarkers[lineMarkerIndex]);
		}

		this._halfwayPointMarkers = [];
		this._lineMarkers.splice(0, this._lineMarkers.length);
		this._unbindMapClick();
		let map;
		L.Polyline.prototype.onRemove.call(this, map);

	},
	setLatLngs: function (latlngs) {
		L.Polyline.prototype.setLatLngs.call(this, latlngs);
	},
	setReadOnly: function (readOnly) {
		let markerFunction;
		let halfwayMarkerFunction;
		if (readOnly && !this.options.readOnly) {
			markerFunction = '_unbindMarkerEvents';
			halfwayMarkerFunction = '_unbindHalfwayMarker';
			this._unbindMapClick();
		} else if (!readOnly && this.options.readOnly) {
			markerFunction = '_bindMarkerEvents';
			halfwayMarkerFunction = '_bindMarkerEvents';
			this._bindMapClick();
		}
		if (typeof markerFunction !== 'undefined') {
			this.options.readOnly = readOnly;
			for (const pointMarkerIndex in this._halfwayPointMarkers) {
				this[halfwayMarkerFunction](this._halfwayPointMarkers[pointMarkerIndex]);
			}
			for (const lineMarkerIndex in this._lineMarkers) {
				this[markerFunction](this._lineMarkers[lineMarkerIndex]);
			}
		}
	},
	_bindMapClick: function () {
		this._map.on('click', this._onMapClick, this);
	},
	_unbindMapClick: function () {
		this._map.off('click', this._onMapClick, this);
	},
	_setExistingLatLngs: function (latlngs) {
		this._existingLatLngs = latlngs;
	},
	_replot: function () {
		this._redraw();
		//this._redrawHalfwayPoints();
	},
	_getNewMarker: function (latlng, options) {
		return new L.marker(latlng, options);
	},
	_unbindMarkerEvents: function (marker) {
		marker.off('click', this._removePoint, this);
		marker.off('drag', this._replot, this);
		marker.dragging.disable()
	},
	_bindMarkerEvents: function (marker) {
		marker.on('click', this._removePoint, this);
		marker.on('drag', this._replot, this);
		marker.dragging.enable()
	},
	_bindHalfwayMarker: function (marker) {
		marker.on('click', this._addHalfwayPoint, this);
	},
	_unbindHalfwayMarker: function (marker) {
		marker.off('click', this._addHalfwayPoint, this);
	},
	_addToMapAndBindMarker: function (newMarker) {
		newMarker.addTo(this._map);
		if (!this.options.readOnly) {
			this._bindMarkerEvents(newMarker);
		}
	},
	_removePoint: function (e) {
		this._map.removeLayer(this._lineMarkers[this._lineMarkers.indexOf(e.target)]);
		this._lineMarkers.splice(this._lineMarkers.indexOf(e.target), 1);
		this._replot();
	},
	_onMapClick: function (e) {
		this._addNewMarker(e);
		this._replot();
	},
	_addNewMarker: function (e) {
		var newMarker = this._getNewMarker(e.latlng, {icon: this._editIcon});
		this._addToMapAndBindMarker(newMarker);
		this._lineMarkers.push(newMarker);

		return newMarker;
	},
	_redrawHalfwayPoints: function () {

	},
	// eslint-disable-next-line no-unused-vars
	_addHalfwayPoint: function (e) {

	},
	_plotExisting: function () {

		for (const latLngsIndex in this._existingLatLngs) {
			this._addNewMarker({
				latlng: new L.LatLng(
					this._existingLatLngs[latLngsIndex].lat,
					this._existingLatLngs[latLngsIndex].lon
				)
			}).bindTooltip(this._existingLatLngs[latLngsIndex].title);
		}
		this._replot();
	},
	_redraw: function () {

		this.setLatLngs([]);
		this.redraw();

		for (const lineMarkerIndex in this._lineMarkers) {
			this.addLatLng(this._lineMarkers[lineMarkerIndex].getLatLng());
		}
		this.redraw();
	}
});

L.Polyline.Plotter = function (latlngs, options) {
	return new L.Polyline.plotter(latlngs, options);
};


//import MyMapSectorChart from '../MyMapSectorChart';
//import MyLegend from '../MyLegend';

// Fix with marker icon not shown: https://github.com/Leaflet/Leaflet/issues/4968
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	/* eslint-disable */
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	/* eslint-enable */
});

let markers = "";

export default class Map extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// eslint-disable-next-line react/prop-types
			geo: props.geo,
		}
	}

	onMapChange(e) {
		if (this.inRequest)
			return;

		//console.log('updateMyMap', e);
		if (!this.mapLoaded) { // check events before map initially loaded
			if (e.type !== 'load') {
				return;
			}
			this.mapLoaded = true;
			//this.props.onLoad(this);
		}

	}

	componentDidMount() {
		// init map
		this.mymap = L.map('map', {scrollWheelZoom: true});
		//this.mymap.scrollWheelZoom = true;
		this.mymap.on('load', this.onMapChange);
		this.mymap.on('resize', this.onMapChange);
		this.mymap.on('viewreset', this.onMapChange);
		this.mymap.on('zoomend', this.onMapChange);
		this.mymap.on('moveend', this.onMapChange);

		L.tileLayer('https://api.mapbox.com/styles/v1/ivanov-a/ckpnpwz5w0poo17rq4vbmdxmo/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaXZhbm92LWEiLCJhIjoiY2twbnFnZHNvMDBsMjJwbG1hdTB0cXk4cCJ9.E2g22ZhBoUEP5Ap8wMbKrw', {
			attribution: '',
			tileSize: 512,
			zoomOffset: -1,
			maxZoom: 20,
			minZoom: 5
		}).addTo(this.mymap);


		this.updateLegend();

	}
    clearLegend(){

    }

	updateLegend() {
		if (!this.mymap)
			return;

		if (this.legend) {
			this.mymap.removeControl(this.legend);
		}

		this.legend = L.control({position: 'bottomleft'});
		// eslint-disable-next-line no-unused-vars
		this.legend.onAdd = (map) => {
			var div = L.DomUtil.create('div', 'info legend');
			//ReactDOM.render(<MyLegend DIC={this.props.DIC} />, div);
			return div;
		};
		this.legend.addTo(this.mymap);

		if (markers != "") {
			markers.onRemove();
		}



		if (this.state.geo.length > 0) {
			markers = L.Polyline.Plotter(this.state.geo, {
				weight: 3,
				readOnly: true,
			}).addTo(this.mymap);

            this.mymap.setMaxZoom(17);
            this.mymap.fitBounds(markers.getBounds());
		}else {
            this.mymap.setView([55.58526, 37.125619], 6);
        }

	}

	componentDidUpdate() {

		// eslint-disable-next-line react/prop-types
        if(this.diff(this.state.geo, this.props.geo)) {

			// eslint-disable-next-line react/prop-types
            this.setState({geo: this.props.geo});
            setTimeout(() => {
                this.updateLegend();
            }, 100);

        }

	}

	render() {
		return <div id="map"></div>
	}

    diff(arr1, arr2) {
        let len = Math.max(arr1.length, arr2.length);

        for (let i = 0; i < len; i++) {
            if (arr1[i] !== arr2[i])
                return true;
        }
        return false;
    }
}
